import { AllowedComponentsContainer } from '@adobe/aem-react-editable-components';
import { cloneElement } from 'react';

class FindADoctorContainer extends AllowedComponentsContainer {
    connectComponentWithItem(ChildComponent, itemProps, itemKey) {
        const itemPath = this.getItemPath(itemKey);

        return (
            <ChildComponent
                {...itemProps}
                key={itemPath}
                cqPath={itemPath}
                aemNoDecoration={true}
                isInEditor={this.props.isInEditor}
                containerProps={this.getItemComponentProps(itemProps, itemKey, itemPath)}
            />
        );
    }

    render() {
        const providerSearch = this.childComponents[0];
        const initialChildren = this.childComponents[1];
        const resultsChildren = this.childComponents[2];

        return this.props.isInEditor ? (
            <>
                {providerSearch}
                <p style={{ textAlign: 'center' }}>Landing State</p>
                {initialChildren}
                <p style={{ textAlign: 'center' }}>Results State</p>
                {resultsChildren}
            </>
        ) : (
            cloneElement(providerSearch, {
                ...providerSearch.props,
                initialChildren,
                resultsChildren,
            })
        );
    }
}

export default FindADoctorContainer;
